export const ONLY_NUMBERS = /^\d+$/;
export const ONLY_NUMBERS_OR_EMPTY_STRING = /^\d*$/;
export const ONLY_NUMBERS_AND_DASH = /^[0-9-]*$/;

export const URL =
  /^(https?:\/\/|http:\/\/www\.|www\.|http:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]+)*$/gi;

export const EIN_REGEX = /^(\d{2}-\d{7})|(\*{2}-\*{3}\d{4})$/;
export const ENCRYPTED_EIN = /^\*{2}-\*{3}\d{4}$/;

export const PHONE_REGEX =
  /^(\(\d{3}\) \d{3}-\d{4})|(\d\(\d{3}\) \d{3}-\d{4})|\d{10,11}$/;

export const SSN_REGEX =
  /^((?!666|000)\d{3}-(?!00)\d{2}-(?!0{4})\d{4})|(\*{3}-\*{2}-\d{4})$/;
export const SIN_REGEX =
  /^((?!666|000)\d{3}-(?!00)\d{3}-(?!0{3})\d{3})|(\*{3}-\*{3}-\d{3})$/;
export const ENCRYPTED_SSN = /^\*{3}-\*{2}-\d{4}$/;

export const ZIP_CODE = /^\d{5}$/;

export const PO_BOX =
  /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

export const USERNAME_REGEX = /^[a-zA-Z0-9]+$/;
